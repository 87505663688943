.inputContainer {
  display: flex;
  align-items: center;
  border: 1px solid #32334f;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 14px;
  margin-top: 6px;
}

.inputBox {
  border-radius: radius;
  border: none;
  outline: none;
  flex: 1;
  background-repeat: no-repeat;
  background-position: left 8px center;
  background-size: 16px 16px;
  font-size: 0.75rem;
}
